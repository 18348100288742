import Accordion from "accordion-js";
import "slick-carousel";

import "../util/remodal";

import LazyLoad from "vanilla-lazyload";

export default {
  init() {
    $(".slider-actualites").slick({
      //lazyLoad: 'ondemand',
      dots: false,
      infinite: true,
      adaptiveHeight: true,
      arrows: true,
      appendArrows: ".arrows__actualites",
      nextArrow: '<i class="material-icons right">keyboard_arrow_right</i>',
      prevArrow: '<i class="material-icons left">keyboard_arrow_left</i>',
    });
    if (jQuery("#submenu > li").hasClass("current_page_item")) {
      jQuery("#subsubmenu").appendTo("#menu-lvl3 .current_page_item");
    }

    if (document.querySelector(".accordion-container") !== null) {
      const accordion = new Accordion(".accordion-container", {
        onOpen: function (currentElement) {
          console.log(currentElement);
        },
      });
    }

    const emptyRight =
      $(".page-int__right").val() === undefined
        ? true
        : $(".page-int__right").html().trim().length == 0;

    const emptyLeft =
      $(".page-int__left").val() === undefined
        ? true
        : $(".page-int__left").html().trim().length == 0;

    if (emptyRight && emptyLeft) {
      $("main").addClass("noRight-noLeft");
    } else if (emptyRight) {
      $("main").addClass("noRight");
    } else if (emptyLeft) {
      $("main").addClass("noLeft");
    }
    console.log(emptyRight);
    console.log(emptyLeft);

    $(" #submit-filter").on("click", function () {
      console.log("click");

      var filter = $("#filter");
      var loading = $(".load-bar");
      $.ajax({
        url: filter.attr("action"),
        data: filter.serialize(),
        type: filter.attr("method"),
        beforeSend: function (xhr) {
          $(".events").html("");
          loading.toggleClass("invisible");
        },
        success: function (data) {
          loading.toggleClass("invisible");

          $(".events").html(data);
          new LazyLoad({
            elements_selector: ".lazy",
          });
        },
      });
      return false;
    });

    $("#submit-filter-annuaire").on("click", function (e) {
      e.preventDefault;
      console.log("click");

      var filter = $("#filter-annuaires");
      var loading = $(".load-bar");
      $.ajax({
        url: filter.attr("action"),
        data: filter.serialize(),
        type: "POST",
        action: "ajax_function_annuaire",
        beforeSend: function (xhr) {
          $(".annuaire").html("");
          loading.toggleClass("invisible");
        },
        success: function (data) {
          loading.toggleClass("invisible");
          $(".annuaire").html(data);

          new LazyLoad({
            elements_selector: ".lazy",
          });

          const accordion = new Accordion(".accordion-container");
          $(".remodal").remodal();
        },
      });
      return false;
    });

    jQuery(".acf-field input:checkbox").removeAttr("checked");
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
