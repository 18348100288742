// Styles SCSS
import "../sass/app.scss";

// Router
import Router from "./util/Router";
import "./util/remodal";
import common from "./routes/common";
import home from "./routes/home";
import page from "./routes/page";
//import associations from './routes/associations';

// SVG polyfiil
import "svgxuse";

// LazyLoad
import lazyLoadInit from "./lazyload-init";
lazyLoadInit();

import { Tabs } from "./util/tabs";

// pushbar
import Pushbar from "./util/pushbar";

import "@fancyapps/fancybox";
/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  /** All pages */
  common,
  /** Home page */
  home,
  /** page */
  page,
});

/** Load Events */

jQuery(document).ready(() => {
  routes.loadEvents();
  if (!$(".envira-gallery-item")[0]) {
    // Do something if class exists
    jQuery(
      'a[href*=".jpg"], a[href*=".jpeg"], a[href*=".png"], a[href*=".gif"]'
    ).fancybox({});
  }
  if (document.getElementById("tabs") !== null) {
    var tabs = new Tabs({
      elem: "tabs",
      open: 0,
    });
  }

  const pushbar = new Pushbar({
    blur: true,
    overlay: true,
  });
});
