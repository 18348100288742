export default {
  init() {
    // Ajout class sur parent pour faire apparaitre UL
    jQuery('#menu-principal li a').focus(function() {
      jQuery(this)
        .parent()
        .addClass('hover');
    });
    jQuery('#menu-principal li a').focusout(function() {
      jQuery(this)
        .parent()
        .removeClass('hover');
    });

    jQuery('#menu-principal li ul li a').focus(function() {
      jQuery(this)
        .parent()
        .parent()
        .addClass('hover');
    });
    jQuery('#menu-principal li ul li a').focusout(function() {
      jQuery(this)
        .parent()
        .parent()
        .removeClass('hover');
    });
    // JavaScript to be fired on all pages
    jQuery('.menu-item-img').each(function() {
      const menu = jQuery(this).find('.sub-menu');
      const img = jQuery(this).find('.img-menu');
      if (menu.length) {
        img.appendTo(menu);
      } else {
        img.css('display', 'none');
      }
    });
    console.log('common');
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  }
};
