import "slick-carousel";
// jQuery
export default {
  init() {
    console.log("home");
    jQuery(document).ready(() => {
      $(".flash-infos").slick({
        dots: true,
        infinite: true,
        fade: true,
        cssEase: "linear",
        arrows: false,
      });

      $(".slider-actualites").slick({
        //lazyLoad: 'ondemand',
        dots: true,
        infinite: true,
        adaptiveHeight: true,
        arrows: true,
        appendArrows: ".arrows__actualites",
        nextArrow: '<i class="material-icons right">keyboard_arrow_right</i>',
        prevArrow: '<i class="material-icons left">keyboard_arrow_left</i>',
      });
      $(".slider-bandeau").slick({
        //lazyLoad: 'ondemand',
        dots: false,
        infinite: true,
        arrows: false,
        fade: true,
        cssEase: "linear",
        autoplay: true,
        autoplaySpeed: 3000,
      });

      $(".slider-agenda").slick({
        lazyLoad: "ondemand",
        dots: true,
        infinite: true,
        arrows: true,
        adaptiveHeight: true,
        appendArrows: ".arrows__agenda",
        nextArrow: '<i class="material-icons right">keyboard_arrow_right</i>',
        prevArrow: '<i class="material-icons left">keyboard_arrow_left</i>',
      });
    });

    if (jQuery(window).width() <= 1199) {
      jQuery(".un-clic").insertAfter(".slider__actu");
    }
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
